import { validators, err } from '../utils';
import axios, { AxiosResponse } from 'axios';
import { Config } from '../types/config';

const URL = '/api/config';

const getConfig = async (): Promise<Config> => {
  try {
    const res: AxiosResponse<Record<string, string | string[]>> =
      await axios.get(`${URL}/client`);
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
    const { data } = res;
    const {
      reactAuthClientID,
      authDomain,
      protectAuthAudience,
      zendeskKey,
      dwollaEnv,
      dwollaDisabled,
      rudderstackKey,
      newLangTesting,
      testTransKeys,
      hygraphRefreshRateMinutes,
      hygraphURL,
      hygraphStage,
      hygraphFallbackURL,
      hygraphUsername,
      hygraphPassword,
    } = data;

    validators.stringValidator(reactAuthClientID, 'reactAuthClientID', {
      nonEmpty: true,
    });
    validators.stringValidator(authDomain, 'authDomain', { nonEmpty: true });
    validators.stringValidator(protectAuthAudience, 'protectAuthAudience', {
      nonEmpty: true,
    });
    validators.stringValidator(zendeskKey, 'zendeskKey', { nonEmpty: true });

    validators.stringValidator(dwollaEnv, 'dwollaEnv', { nonEmpty: true });

    validators.stringValidator(rudderstackKey, 'rudderstackKey', {
      nonEmpty: true,
    });
    validators.stringValidator(newLangTesting, 'newLangTesting', {
      nonEmpty: true,
    });
    validators.stringValidator(testTransKeys, 'testTransKeys', {
      nonEmpty: true,
    });
    validators.stringValidator(
      hygraphRefreshRateMinutes,
      'hygraphRefreshRateMinutes',
      {
        nonEmpty: true,
      },
    );
    validators.stringValidator(hygraphURL, 'hygraphURL', {
      nonEmpty: true,
    });
    validators.stringValidator(hygraphFallbackURL, 'hygraphFallbackURL', {
      nonEmpty: true,
    });
    validators.stringValidator(hygraphUsername, 'hygraphUsername', {
      nonEmpty: true,
    });
    validators.stringValidator(hygraphPassword, 'hygraphPassword', {
      nonEmpty: true,
    });
    validators.stringValidator(hygraphStage, 'hygraphStage', {
      nonEmpty: true,
    });

    return {
      reactAuthClientID,
      authDomain,
      protectAuthAudience,
      zendeskKey,
      dwollaEnv,
      dwollaDisabled: dwollaDisabled === 'true',
      rudderstackKey,
      newLangTesting,
      testTransKeys,
      hygraphRefreshRateMinutes,
      hygraphURL,
      hygraphFallbackURL,
      hygraphUsername,
      hygraphPassword,
      hygraphStage,
    };
  } catch (e) {
    throw err.handleError(e, 'error fetching client config');
  }
};

export default {
  getConfig,
};
