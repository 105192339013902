import { ForecastData, type Policy } from '../../utils/types/Entity';
import { CurrencyUnit } from '../../utils/types/Currency/Currency.types';

export interface PolicyListItemProps {
  policy: Policy;
  hoursCovered: number;
  clickHandler: (id: string) => void;
  isExpanded: boolean;
  currency?: CurrencyUnit;
  forecast?: ForecastData;
}

export enum PolicyListItemStatus {
  Upcoming = 'upcoming', // PolicyStatus.Upcoming
  NoPayout = 'no payout', // PolicyStatus.Completed && claims === null
  Due = 'due', // ClaimStatus.Open && Claim.Payout === null
  Processing = 'processing', // Claim.Payout.PayoutStatus === 'processing'
  PayoutFailed = 'payout failed', // Claim.Payout.PayoutStatus === 'payout failed'
  Paid = 'paid', // ClaimStatus === 'paid'
  ClaimCanceled = 'claim canceled', // ClaimStatus === 'canceled'
  PayoutCanceled = 'payout canceled', // Claim.Payout.PayoutStatus === 'canceled'
}
