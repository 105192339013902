/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react';
import { entityHelpers } from '../../utils';
import { PolicyListItem } from '../../components';
import { PolicyListContainerProps } from './PolicyListContainer.types';
import styles from './policy-list-container.module.scss';
import { ForecastData, type Policy } from '../../utils/types/Entity';
import { ForecastAPI } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import sensibleDate from '../../utils/types/SensibleDate';

const PolicyListContainer: React.FC<PolicyListContainerProps> = ({
  bundle,
}): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const [expandedID, setExpandedID] = useState<string>('');
  const [forecasts, setForecasts] = useState<Record<string, ForecastData>>({});

  useEffect(() => {
    const policy = bundle.policies.find((policy) => policy.id === expandedID);
    if (forecasts[expandedID] || !policy) return;

    const fetchForecastData = async () => {
      const forecastLookaheadLimit = 1000 * 60 * 60 * 24 * 14; //2 weeks
      const disableDate = new Date(
        new Date().getTime() + forecastLookaheadLimit,
      );
      const disabledFuturePolicyDetails = sensibleDate.isBefore(
        disableDate,
        sensibleDate.parseRFC(policy.start),
      );
      if (disabledFuturePolicyDetails) {
        return;
      }

      const token = await getAccessTokenSilently();

      const newForecast = await ForecastAPI.getPolicyForecast(token, policy);
      if (!newForecast) return;
      setForecasts((oldForecasts) => {
        return {
          ...oldForecasts,
          [expandedID]: newForecast?.data,
        };
      });
    };
    void fetchForecastData();
  }, [expandedID]);
  const clickHandler = (id: string): void => {
    const willExpand = expandedID !== id;
    if (willExpand) {
      setExpandedID(id);
      const policy = bundle.policies.find((policy) => policy.id === id);
      if (!policy) {
        throw new Error(
          `Cannot find policy with id ${id} in bundle ${bundle.id}`,
        );
      }
    } else {
      setExpandedID('');
    }
  };

  return (
    <div className={styles.container}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
      {bundle.policies.map((policy: Policy, i: unknown) => {
        const hoursCovered = entityHelpers.totalLossesForPolicy(policy);

        return (
          <PolicyListItem
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`pol_${i}`}
            policy={policy}
            hoursCovered={hoursCovered}
            clickHandler={clickHandler}
            isExpanded={expandedID === policy.id}
            forecast={forecasts[policy.id]}
            currency={policy.currency}
          />
        );
      })}
    </div>
  );
};

export default PolicyListContainer;
